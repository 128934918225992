import React, { useState } from "react";
import Burger from "./burger";
import MobileMenu from "./mobileMenu";
import styled from "styled-components";
import SignOutModal from "./signOut";

const MobileMenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const MobileNavBarSpacer = styled.div`
  position: relative;
  width: 100vw;
  height: 80px;
`;

const MobileNav = (props) => {
  const [open, setOpen] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => {
    setOpenModal(true);
    setOpen(false);
  };
  const handleClose = () => setOpenModal(false);
  return (
    <MobileMenuWrapper>
      <MobileNavBarSpacer />
      <SignOutModal open={openModal} handleClose={handleClose} />
      <Burger open={open} setOpen={setOpen} />
      <MobileMenu
        userData={props.userData}
        authUser={props.authUser}
        open={open}
        setOpen={setOpen}
        openSignOut={handleOpen}
      />
    </MobileMenuWrapper>
  );
};

export default MobileNav;
