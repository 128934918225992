import React, { useState, useEffect } from "react";
import { ProvideAuth, useAuth } from "./services/use-auth";
import { onAuthStateChanged } from "firebase/auth";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";

import * as ROUTES from "./constants/routes";
import Nav from "./scenes/nav";
import HomePage from "./scenes/home";
import SignInPage from "./scenes/session_management/signIn";
import SignUpPage from "./scenes/session_management/signUp";
import AccountPage from "./scenes/session_management/account";
import AdminPage from "./scenes/business_admin/admin";

const App = () => {
  const AppInSession = () => {
    const [user, setUser] = useState(null);
    const [userData, setUserData] = useState({});
    const [showLoading, setShowLoading] = useState(false);

    const authHook = useAuth();

    useEffect(() => {
      const unsubscribe = onAuthStateChanged(authHook.auth, (user) => {
        console.log(`The auth listener has triggered in the App.js file`);
        if (user) {
          console.log(`There appears to be a user with UID: ${user.uid}`);
          setUser(user);
          const fetcUser = async () => {
            const docRef = doc(authHook.db, "users", user.uid);
            const docSnap = await getDoc(docRef);
            setUserData(docSnap.data());
          };
          if (user) {
            fetcUser();
          }
        } else {
          setUser(null);
          setUserData({});
        }
      });
      return () => {
        unsubscribe();
      };
    }, []);

    return (
      <BrowserRouter>
        {/*All route components go here and can recieve auth state */}
        <Nav authUser={user} userData={userData} showLoading={showLoading} />
        <Routes>
          <Route
            exact
            path={ROUTES.HOME}
            element={<HomePage authUser={user} userData={userData} />}
          />

          <Route exact path={ROUTES.SIGN_IN} element={<SignInPage />} />
          <Route exact path={ROUTES.SIGN_UP} element={<SignUpPage />} />
          <Route
            exact
            path={ROUTES.ACCOUNT}
            element={<AccountPage authUser={user} userData={userData} setShowLoading={setShowLoading} />}
          />
          <Route
            exact
            path={ROUTES.ADMIN}
            element={
              <AdminPage
                authUser={user}
                userData={userData}
                setShowLoading={setShowLoading}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    );
  };
  return (
    <ProvideAuth>
      <AppInSession />
    </ProvideAuth>
  );
};

export default App;
