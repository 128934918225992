import React, {useContext, createContext} from "react";
import {initializeApp} from "firebase/app";
import {getFirestore, connectFirestoreEmulator} from "firebase/firestore";
import {getAuth, connectAuthEmulator} from "firebase/auth";
import {getFunctions, connectFunctionsEmulator} from "firebase/functions";

//Adapted from : https://usehooks.com/useAuth/

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: "course-schedge.firebaseapp.com",
//   projectId: "course-schedge",
//   storageBucket: "course-schedge.appspot.com",
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDING_ID,
//   appId: process.env.REACT_APP_FIREBASE_APPLICATION_ID,
// };

const configDirect = {
  apiKey: "AIzaSyBWzBm2NsL5oUboUHzkd1nBBA9oF4TKX28",
  authDomain: "inventory-manager-ft.firebaseapp.com",
  projectId: "inventory-manager-ft",
  storageBucket: "inventory-manager-ft.appspot.com",
  messagingSenderId: "136347900071",
  appId: "1:136347900071:web:58ea1819ca6f54a2a02382",
  measurementId: "G-TLP364CFEV",
};

const authContext = createContext();

//Wraps the app and makes auth object available to any child calling useAuth()
export const ProvideAuth = ({children}) => {
  //This is running twice for some reason
  const authHook = useProvideAuth();

  return (
    <authContext.Provider value={authHook}>{children}</authContext.Provider>
  );
};

//Hook for child compoenets to get the auth object
//and re-render upon change.
export const useAuth = () => {
  return useContext(authContext);
};

//Provider hook that creates auth object and handles state
const useProvideAuth = () => {
  console.log("Triggering ProvideAuth Hook to run");

  const app = initializeApp(configDirect);
  const db = getFirestore(app);
  const auth = getAuth(app);
  const func = getFunctions(app);

  if (window.location.href.split("/")[2].split(":")[0] === "localhost") {
    console.log(`Connecting emulators since the address is localhost`);
    connectFirestoreEmulator(db, "localhost", 8080);
    connectAuthEmulator(auth, "http://127.0.0.1:9099");
    connectFunctionsEmulator(func, "localhost", 5001);
  }

  return {
    app,
    db,
    auth,
    func,
  };
};
