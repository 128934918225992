import React, { useState } from "react";
import { doc, collection, setDoc, addDoc } from "firebase/firestore";
import { useAuth } from "../../services/use-auth";

import { Autocomplete, Box, TextField } from "@mui/material";
import styled from "styled-components";
import StyledButton from "../../assets/buttons";

const ParentWrapper = styled.div`
  margin: 20px;
`;

const CreateBusinessFlow = () => {
  const authHook = useAuth();

  const [createBusinessData, setCreateBusinessData] = useState({
    businessName: "",
  });
  const [entryErrors, setEntryErrors] = useState({
    password: false,
    passwordConfirm: false,
  });
  const [entryFeedback, setEntryFeedback] = useState({
    password: null,
    passwordConfirm: null,
  });

  const handleChange = (event) => {
    setCreateBusinessData((createBusinessData) => ({
      ...createBusinessData,
      [event.target.name]: event.target.value,
    }));
  };

  const validateBusinessName = () => {
    if (createBusinessData.businessName === "") {
      setEntryErrors((entryErrors) => ({
        ...entryErrors,
        businessName: true,
      }));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        businessName: "You must enter a valid name",
      }));
    } else {
      setEntryErrors((entryErrors) => ({
        ...entryErrors,
        businessName: false,
      }));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        businessName: null,
      }));
    }
  };

  const validateBusinessStreet = () => {
    if (createBusinessData.businessStreet === "") {
      setEntryErrors((entryErrors) => ({
        ...entryErrors,
        businessStreet: true,
      }));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        businessStreet: "You must enter a valid name",
      }));
    } else {
      setEntryErrors((entryErrors) => ({
        ...entryErrors,
        businessStreet: false,
      }));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        businessStreet: null,
      }));
    }
  };

  const validateBusinessCity = () => {
    if (createBusinessData.businessCity === "") {
      setEntryErrors((entryErrors) => ({
        ...entryErrors,
        businessCity: true,
      }));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        businessCity: "You must enter a valid name",
      }));
    } else {
      setEntryErrors((entryErrors) => ({
        ...entryErrors,
        businessCity: false,
      }));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        businessCity: null,
      }));
    }
  };

  const validateBusinessZip = () => {
    if (createBusinessData.businessZip === "") {
      setEntryErrors((entryErrors) => ({
        ...entryErrors,
        businessZip: true,
      }));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        businessZip: "You must enter a valid name",
      }));
    } else {
      setEntryErrors((entryErrors) => ({
        ...entryErrors,
        businessZip: false,
      }));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        businessZip: null,
      }));
    }
  };

  const validateBusinessState = () => {
    if (createBusinessData.businessState === "") {
      setEntryErrors((entryErrors) => ({
        ...entryErrors,
        businessState: true,
      }));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        businessState: "You must enter a valid name",
      }));
    } else {
      setEntryErrors((entryErrors) => ({
        ...entryErrors,
        businessState: false,
      }));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        businessState: null,
      }));
    }
  };

  const submitCreateBusiness = async () => {
    const user = authHook.auth.currentUser;
    const newDoc = await addDoc(collection(authHook.db, "businesses"), {
      owner: user.uid,
      businessName: createBusinessData.businessName,
      subscriptionCurrent: false,
      address: {
        street: createBusinessData.businessStreet,
        city: createBusinessData.businessCity,
        state: createBusinessData.businessState,
        zip: createBusinessData.businessZip,
      },
    });
    console.log(newDoc.id);
    await setDoc(
      doc(authHook.db, "users", user.uid),
      {
        businessName: createBusinessData.businessName,
        businessID: newDoc.id,
        roles: { admin: true },
        address: {
          street: createBusinessData.businessStreet,
          city: createBusinessData.businessCity,
          state: createBusinessData.businessState,
          zip: createBusinessData.businessZip,
        },
      },
      { merge: true }
    );
  };
  const invalid =
    entryErrors.businessName || createBusinessData.businessName === "";

  const states = [
   {label: "AL", value: "AL"},
   {label: "AK", value: "AK"},
    {label: "AS", value: "AS"},
    {label: "AZ", value: "AZ"},
    {label: "AR", value: "AR"},
    {label: "CA", value: "CA"},
    {label: "CO", value: "CO"},
    {label: "CT", value: "CT"},
    {label: "DE", value: "DE"},
    {label: "DC", value: "DC"},
    {label: "FM", value: "FM"},
    {label: "FL", value: "FL"},
    {label: "GA", value: "GA"},
    {label: "GU", value: "GU"},
    {label: "ID", value: "ID"},
    {label: "IL", value: "IL"},
    {label: "IN", value: "IN"},
    {label: "IA", value: "IA"},
    {label: "KS", value: "KS"},
    {label: "KY", value: "KY"},
    {label: "LA", value: "LA"},
    {label: "ME", value: "ME"},
    {label: "MH", value: "MH"},
    {label: "MD", value: "MD"},
    {label: "MA", value: "MA"},
    {label: "MI", value: "MI"},
    {label: "MN", value: "MN"},
    {label: "MS", value: "MS"},
    {label: "MO", value: "MO"},
    {label: "MT", value: "MT"},
    {label: "NE", value: "NE"},
    {label: "NV", value: "NV"},
    {label: "NH", value: "NH"},
    {label: "NJ", value: "NJ"},
    {label: "NM", value: "NM"},
    {label: "NY", value: "NY"},
    {label: "NC", value: "NC"},
    {label: "ND", value: "ND"},
    {label: "MP", value: "MP"},
    {label: "OH", value: "OH"},
   {label: "OK", value: "OK"},
    {label: "OR", value: "OR"},
    {label: "PW", value: "PW"},
    {label: "PA", value: "PA"},
    {label: "PR", value: "PR"},
    {label: "RI", value: "RI"},
    {label: "SC", value: "SC"},
    {label: "SD", value: "SD"},
    {label: "TN", value: "TN"},
    {label: "TX", value: "TX"},
    {label: "UT", value: "UT"},
    {label: "VT", value: "VT"},
    {label: "VI", value: "VI"},
    {label: "VA", value: "VA"},
    {label: "WA", value: "WA"},
    {label: "WV", value: "WV"},
    {label: "WI", value: "WI"},
    {label: "WY", value: "WY"},
  ];

  return (
    <ParentWrapper>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={{ p: 4, gap: 4, display: "flex", flexDirection: "column" }}
      >
        <TextField
          label="Business Name"
          name="businessName"
          variant="outlined"
          color="secondary"
          value={createBusinessData["businessName"]}
          onChange={handleChange}
          onBlur={() => validateBusinessName()}
          error={entryErrors.businessName}
          helperText={entryFeedback.businessName}
        />
        <TextField
          label="Business Street Address"
          name="businessStreet"
          variant="outlined"
          color="secondary"
          value={createBusinessData["businessStreet"]}
          onChange={handleChange}
          onBlur={() => validateBusinessStreet()}
          error={entryErrors.businessStreet}
          helperText={entryFeedback.businessStreet}
        />
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <TextField
            label="City"
            name="businessCity"
            variant="outlined"
            color="secondary"
            value={createBusinessData["businessCity"]}
            onChange={handleChange}
            onBlur={() => validateBusinessCity()}
            error={entryErrors.businessCity}
            helperText={entryFeedback.businessCity}
          />
          <TextField
            label="State"
            name="businessState"
            variant="outlined"
            color="secondary"
            value={createBusinessData["businessState"]}
            onChange={handleChange}
            onBlur={() => validateBusinessState()}
            error={entryErrors.businessState}
            helperText={entryFeedback.businessState}
          />
          <TextField
            label="Zip"
            name="businessZip"
            variant="outlined"
            color="secondary"
            value={createBusinessData["businessZip"]}
            onChange={handleChange}
            onBlur={() => validateBusinessZip()}
            error={entryErrors.businessZip}
            helperText={entryFeedback.businessZip}
          />
        </Box>
      </Box>
      <StyledButton
        disabled={invalid}
        primary
        callback={() => submitCreateBusiness()}
        width="350px"
        fontSize="26px"
      >
        Create New Business
      </StyledButton>
    </ParentWrapper>
  );
};

export default CreateBusinessFlow;
