import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../services/use-auth";
import {collection, query, where, getDocs} from "firebase/firestore";
import {httpsCallable} from "firebase/functions";

import {Payment} from "./payment";

import * as ROLES from "../../constants/roles";
import * as ROUTES from "../../constants/routes";

const conditionAdmin = (authUser) => authUser && !!authUser.roles[ROLES.ADMIN];
const conditionAuthUser = (authUser) => !!authUser;

const ProtectedAdminPage = (props) => {
  const authHook = useAuth();

  const [business, setBusiness] = useState({});

  const callTest = () => {
    const functions = authHook.func;
    const testFunc = httpsCallable(functions, "testFunc");
    testFunc().then((r) => console.log(r.data));
  };

  useEffect(() => {
    const businessesRef = collection(authHook.db, "businesses");
    const busQuery = query(
      businessesRef,
      where("owner", "==", props.authUser.uid)
    );
    if (props.authUser.uid && Object.keys(business).length === 0) {
      props.setShowLoading(true);
      const getBusiness = async () => {
        const querySnapshot = await getDocs(busQuery);
        querySnapshot.forEach((doc) => {
          console.log(doc);
          console.log(`Got business: ${doc.data().businessName}`);
          setBusiness({
            name: doc.data().businessName,
            docId: doc.id,
            subscription: doc.data().subscriptionCurrent,
          });
          if (!doc.data().subscriptionCurrent) {
            //Load payment stuff if subscription isn't current
          }
        });
      };
      props.setShowLoading(false);

      getBusiness();
    }
  }, [authHook.db, props]);

  return (
    <React.Fragment>
      {business.name && (
        <h3>
          Welcome {props.userData.username}, business owner for {business.name}.
          Your subscription is{" "}
          {business.subscription ? `current.` : `not current.`}
        </h3>
      )}
      {business && business.name && !business.subscription && (
        <Payment setShowLoading={props.setShowLoading} userData={props.userData} functions={authHook.func} httpsCallable={httpsCallable} />
      )}
    </React.Fragment>
  );
};

const AdminPage = (props) => {
  const navigate = useNavigate();
  //Redirect here if not admin
  const [isAdmin, setIsAdmin] = useState(false);
  const [loadingUser, setLoadingUser] = useState(true);

  useEffect(() => {
    console.log(`Loading in Admin page`);
    //If they are not logged in at all as an authUser
    if (!conditionAuthUser(props.authUser)) {
      console.log(`No authUser signed in ${props.authUser}`);
      navigate(ROUTES.SIGN_IN);
    } else if (!props.userData.roles || !conditionAdmin(props.userData)) {
      console.log(`The authUser is not an admin ${props.userData.roles}`);
      console.log(`ConditionAdmin is: ${conditionAdmin(props.userData)}`);
      navigate(ROUTES.HOME);
    } else {
      console.log(`This person is an admin.`);
    }
    if (props.userData) {
      if (props.userData.roles) {
        setIsAdmin(props.userData.roles[ROLES.ADMIN]);
      }
      setLoadingUser(false);
    }
  }, [navigate, props.authUser, props.userData]);
  return (
    <React.Fragment>
      {!loadingUser ? (
        isAdmin ? (
          <React.Fragment>
            <ProtectedAdminPage
              authUser={props.authUser}
              userData={props.userData}
              setShowLoading={props.setShowLoading}
            />
          </React.Fragment>
        ) : (
          <h1>You are an IMPOSTER!</h1>
        )
      ) : (
        <h1>Loading</h1>
      )}
    </React.Fragment>
  );
};

export default AdminPage;
