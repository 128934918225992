import React, { useState } from "react";
import { useAuth } from "../../services/use-auth";
import { updatePassword } from "@firebase/auth";
import { Box, TextField } from "@mui/material";

import CreateBusinessFlow from "../create_business_flow/createBusiness";
import StyledButton from "../../assets/buttons";
import { httpsCallable } from "firebase/functions";
import { useNavigate } from "react-router-dom";

const AccountPage = (props) => {
  const authHook = useAuth();

  const [changePWData, setChangePWData] = useState({
    password: "",
    passwordConfirm: "",
  });
  const [entryErrors, setEntryErrors] = useState({
    password: false,
    passwordConfirm: false,
  });
  const [entryFeedback, setEntryFeedback] = useState({
    password: null,
    passwordConfirm: null,
  });

  const handleChange = (event) => {
    setChangePWData((changePWData) => ({
      ...changePWData,
      [event.target.name]: event.target.value,
    }));
  };

  const validatePassword = () => {
    if (changePWData.password === "") {
      setEntryErrors((entryErrors) => ({ ...entryErrors, password: true }));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        password: "You must enter a password",
      }));
    } else {
      const longerThan8 = changePWData.password.length > 8;
      if (!longerThan8) {
        setEntryErrors((entryErrors) => ({ ...entryErrors, password: true }));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          password: "Passwords must be longer than 8 characters",
        }));
      } else {
        setEntryErrors((entryErrors) => ({ ...entryErrors, password: false }));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          password: null,
        }));
      }
    }
  };

  const validatePasswordConfirm = () => {
    if (changePWData.passwordConfirm === "") {
      setEntryErrors((entryErrors) => ({
        ...entryErrors,
        passwordConfirm: true,
      }));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        passwordConfirm: "You must enter a password",
      }));
    } else {
      const match =
        (changePWData.password === changePWData) ===
        changePWData.passwordConfirm;
      if (!match) {
        setEntryErrors((entryErrors) => ({
          ...entryErrors,
          passwordConfirm: true,
        }));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          password: "Your passwords do not match",
        }));
      } else {
        setEntryErrors((entryErrors) => ({
          ...entryErrors,
          passwordConfirm: false,
        }));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          passwordConfirm: null,
        }));
      }
    }
  };

  const invalid =
    changePWData.password === "" ||
    changePWData.passwordConfirm === "" ||
    !entryErrors.password ||
    !entryErrors.passwordConfirm;

  const submitChangePassword = () => {
    const user = authHook.auth.currentUser;
    const newPassword = changePWData.password;

    updatePassword(user, newPassword)
      .then(() => {
        console.log(`Password succesfully updated.`);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const subscriptionManagementPortal = async () => {
    props.setShowLoading(true);
    const functions = authHook.func;
    const launchPortal = httpsCallable(functions, "createPortalSession");
    const getURL = await launchPortal({UID: props.authUser.uid});
    props.setShowLoading(false);
    console.log(getURL.data.url);
    window.location.replace(getURL.data.url);
  }
  
  console.log(props.userData);

  return (
    <React.Fragment>
      <Box component="form" sx={{ p: 4, gap: 4 }} noValidate autoComplete="off">
        <TextField
          label="Password"
          name="password"
          variant="outlined"
          color="secondary"
          value={changePWData["password"]}
          onChange={handleChange}
          onBlur={() => validatePassword()}
          error={entryErrors.password}
          helperText={entryFeedback.password}
        />
        <TextField
          label="Confirm Password"
          name="passwordConfirm"
          variant="outlined"
          color="secondary"
          value={changePWData["passwordConfirm"]}
          onChange={handleChange}
          onBlur={() => validatePasswordConfirm()}
          error={entryErrors.passwordConfirm}
          helperText={entryFeedback.passwordConfirm}
        />
      </Box>
      <StyledButton
        disabled={invalid}
        primary
        callback={submitChangePassword}
        width="350px"
        fontSize="26px">
        Change Password
      </StyledButton>
      {props.userData && !props.userData.roles.admin && <CreateBusinessFlow />}
      {props.userData && props.userData.roles.admin && <StyledButton primary callback={() => subscriptionManagementPortal()}>Manage Subscription</StyledButton>}
    </React.Fragment>
  );
};

export default AccountPage;
