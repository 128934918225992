import React, { useState, useEffect } from "react";
import { useAuth } from "../../services/use-auth";
import { signInWithEmailAndPassword } from "@firebase/auth";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import * as ROUTES from "../../constants/routes";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import StyledButton from "../../assets/buttons";

import backgroundImage from "../../assets/login_image_sparks.jpg";

const BackgroundImage = styled.img`
  position: absolute;
  margin-top: 15px;
  width: 100%;
  height: 100%;
  filter: grayscale(10%);
  /* object-position: 0% 90%; */
  transform: scale(1.03);
  object-fit: cover;
  z-index: -2;
`;

const SignInWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.92);
  padding: 20px;
  border-radius: 8px;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 15vh;
`;

const Title = styled.p`
  font-size: 26px;
  font-family: sans-serif;
`;

const TextButton = styled.p`
  font-family: sans-serif;
  font-size: 18px;
  font-style: strong;
  cursor: pointer;
`;

const SignInPage = () => {
  const authHook = useAuth();
  const navigate = useNavigate();

  const [loginData, setLoginData] = useState({ email: "", password: "" });
  const [entryErrors, setEntryErrors] = useState({
    email: false,
    password: false,
  });
  const [entryFeedback, setEntryFeedback] = useState({
    email: null,
    password: null,
  });

  const handleChange = (event) => {
    setLoginData((loginData) => ({
      ...loginData,
      [event.target.name]: event.target.value,
    }));
  };

  const validateEmail = () => {
    if (loginData.email === "") {
      setEntryErrors((entryErrors) => ({ ...entryErrors, email: true }));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        email: "You must enter a email",
      }));
    } else {
      const containsAtAndPeriod =
        loginData.email.includes("@") && loginData.email.includes(".");
      if (!containsAtAndPeriod) {
        setEntryErrors((entryErrors) => ({ ...entryErrors, email: true }));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          email: "You must enter a valid email address",
        }));
      } else {
        setEntryErrors((entryErrors) => ({ ...entryErrors, email: false }));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          email: null,
        }));
      }
    }
  };

  const validatePassword = () => {
    if (loginData.password === "") {
      setEntryErrors((entryErrors) => ({ ...entryErrors, password: true }));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        password: "You must enter a password",
      }));
    } else {
      const longerThan8 = loginData.password.length > 8;
      if (!longerThan8) {
        setEntryErrors((entryErrors) => ({ ...entryErrors, password: true }));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          password: "Passwords are longer than this",
        }));
      } else {
        setEntryErrors((entryErrors) => ({ ...entryErrors, password: false }));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          password: null,
        }));
      }
    }
  };

  const submitSignIn = () => {
    signInWithEmailAndPassword(
      authHook.auth,
      loginData.email,
      loginData.password
    )
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        navigate(ROUTES.HOME);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorMessage);
      });
  };

  const invalid =
    loginData.username === "" ||
    loginData.password === "" ||
    entryErrors.username ||
    entryErrors.password;

  return (
    <React.Fragment>
      <BackgroundImage src={backgroundImage} />
      <PageWrapper>
        <SignInWrapper>
          <Title>Sign In</Title>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
              pb: 3,
              pl: 2,
              pr: 2,
            }}
            noValidate
            autoComplete="off">
            <TextField
              sx={{ width: "350px", fontSize: "22px" }}
              label="Email Address"
              name="email"
              variant="outlined"
              color="secondary"
              value={loginData["email"]}
              onChange={handleChange}
              onBlur={() => validateEmail()}
              error={entryErrors.email}
              helperText={entryFeedback.email}
            />
            <TextField
              sx={{ width: "350px", fontSize: "22px" }}
              label="Password"
              type="password"
              name="password"
              variant="outlined"
              color="secondary"
              value={loginData["password"]}
              onChange={handleChange}
              onBlur={() => validatePassword()}
              error={entryErrors.password}
              helperText={entryFeedback.password}
            />
          </Box>
          <StyledButton
            disabled={invalid}
            primary
            callback={submitSignIn}
            width="250px"
            fontSize="26px">
            Login
          </StyledButton>
          <TextButton onClick={() => navigate(ROUTES.SIGN_UP)}>
            Don't have an account?
          </TextButton>
        </SignInWrapper>
      </PageWrapper>
    </React.Fragment>
  );
};

export default SignInPage;
