import React from "react";
import styled from "styled-components";

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px;
`;

const HomePage = (props) => {
  return (
    <PageWrapper>
      {props.userData.username && <h3>Hello {props.userData.username}</h3>}
    </PageWrapper>
  );
};

export default HomePage;
