import React from "react";
import { useAuth } from "../../services/use-auth";
import { signOut } from "@firebase/auth";
import * as ROUTES from "../../constants/routes";

import Box from "@mui/material/Box";
import StyledButton from "../../assets/buttons";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const SignOutModal = (props) => {
  const navigate = useNavigate();
  const authHook = useAuth();
  const signOutFunc = () => {
    navigate(ROUTES.HOME);
    signOut(authHook.auth).catch((error) => {
      console.error(error.message);
    });
    props.handleClose();
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Signing Out...
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to sign out of your Schedge account?
          </Typography>
          <br />
          <StyledButton secondary width="300px" callback={signOutFunc}>
            Yes Log Out
          </StyledButton>
        </Box>
      </Modal>
    </div>
  );
};

export default SignOutModal;
