import React, {useState, useEffect} from "react";
import {useStripe, useElements, PaymentElement} from "@stripe/react-stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import StyledButton from "../../assets/buttons";

const stripePromise = loadStripe("pk_live_lOz11BYNFItVqA0lINWQJDK700iEX883yd");

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    props.setShowLoading(true);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: "https://orchestio.com/admin",
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
      props.setShowLoading(false);
    } else {
    props.setShowLoading(false);

      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <StyledButton primary disabled={!stripe}>Submit</StyledButton>
    </form>
  )
};


export const Payment = (props) => {
 const [secret, setSecret] = useState(undefined);
  const subscribe = async () => {
    const start = props.httpsCallable(props.functions, "setupSubscribe");
    const initSecret = await start({UID: props.userData.stripeID, priceID: "price_1LR3QmKDVmoNRkMORREskf1Q"});
    console.log(initSecret.data);
    setSecret(initSecret.data.clientSecret);
  }

  const options = {clientSecret: secret};

 

  return (
    <React.Fragment>
    {secret === undefined && <button onClick={() => subscribe()}>Subscribe</button>}
     {secret !== undefined && (
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm setShowLoading={props.setShowLoading}/>
      </Elements>
     )}
    </React.Fragment>
  );
};
